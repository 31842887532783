import { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "videojs-youtube";
import "video.js/dist/video-js.css";
import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";
import "@silvermine/videojs-quality-selector";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'

// Define initial options for the video player
const initialOptions = {
  controls: true,
  fluid: true,
  enableDocumentPictureInPicture: true,
  enableSmoothSeeking: true,

  controlBar: {
    volumePanel: {
      inline: false
    },
    children: [
      "playToggle",
      "progressControl",
      "volumePanel",
      "currentTimeDisplay",
      "timeDivider",
      "durationDisplay",
      "liveDisplay",
      "playbackRateMenuButton",
      "chaptersButton",
      "fullscreenToggle",
      // "pictureInPictureToggle", // Enable Picture-in-Picture toggle button
    ]
  }
};


window.videoDuration = 0;

const VideoPlayer = ({ source, showFloatVideo }) => {
  const videoNode = useRef(null);
  const player = useRef(null);
  const [source1, setSource] = useState(source);
  const [videoLoader, setVideoLoader] = useState(false);
  const [isFirstPlay, setIsFirstPlay] = useState(true);

  // Update the source state if the source prop changes
  useEffect(() => {
    if (source.src !== source1.src) {
      setSource(source);
    }
  }, [source, source1.src]);

  useEffect(() => {
    if (videoNode.current) {
      setVideoLoader(true)
      const videoJsOptions = {
        sources: [source1],
        playbackRates: [0.5, 1, 1.5, 2],
      };

      //  if(window.innerWidth < 770){
      //     initialOptions['autoplay'] = true;
      //  }else{
      //   initialOptions['autoplay'] = false;
      //  }


      player.current = videojs(videoNode.current, {
        ...initialOptions,
        ...videoJsOptions
      });


      if (!player.current) {
        return;
      }

      if (window.videoDuration) {
        player.current.ready(() => {
          setVideoLoader(false)
          player.current.currentTime(window.videoDuration);
          player.current.play();
        });
      }

      player.current.ready(() => {
        setVideoLoader(false);
        player.current.on('timeupdate', () => {
          window.videoDuration = player.current.currentTime();
        });

        player.current.on('play', () => {
          if (isFirstPlay) {
            setIsFirstPlay(false);
          }
        });
      
        player.current.on('fullscreenchange', function() {
          if (player.current.isFullscreen()) {
            document.body.classList.add('video_fullscreen');
            document.body.style.overflow = 'hidden';
          } else {
            document.body.classList.remove('video_fullscreen');
            document.body.style.overflow = 'auto';
          }
        });
        

        // if (!isFirstPlay) {
          const controlBar = player.current.getChild('controlBar');

          const handleMouseEnter = () => {
            if (controlBar) {
              controlBar.show();
            }
          };

          const handleMouseLeave = () => {
            if (controlBar) {
              controlBar.hide();
            }
          };

          const handleTouchStart = () => {
            if (controlBar) {
              controlBar.show();
            }
            clearTimeout(player.current.controlsTimeout);
          };

          const handleTouchEnd = () => {
            player.current.controlsTimeout = setTimeout(() => {
              if (controlBar) {
                controlBar.hide();
              }
            }, 3000);
          };

          player.current.on("mouseenter", handleMouseEnter);
          player.current.on("touchstart", handleTouchStart);


          player.current.on("mouseleave", handleMouseLeave);
          player.current.on("touchend", handleTouchEnd);


          setTimeout(() => {
            const controlBar = player.current.getChild('controlBar');
            if (controlBar) {
              controlBar.hide();
            }
          }, 2000);

        // }

      });
    }

    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, [source1]);

  const customLoadingIcon = <LoadingOutlined style={{ fontSize: 40, color: '#fff' }} spin />;


  return (
    <div className={`App ${isFirstPlay ? 'pointer_all' : 'pointer_none'}`} style={{ position: 'relative' }}>
      <video ref={videoNode} id="vimeo-video" className="video-js" controls />
      {videoLoader && <div className="spinner_yt"><Spin size="large" indicator={customLoadingIcon} /></div>}
    </div>
  );
};

export default VideoPlayer;

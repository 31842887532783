import { toast } from "react-toastify";
import Cookies from "js-cookie";
import moment from "moment";
import copy from "copy-to-clipboard";
import { appStore } from '../zustand/store'
import svg from "./svg";


import ContentLoader, {
	BulletList,
} from "react-content-loader";
import config from "../config";

export function debounce(fn) {
	let timeout;
	return function (...args) {
		if (timeout) clearTimeout(timeout);

		timeout = setTimeout(() => {
			fn(...args);
		}, 300);
	};
}

export let common = {
	convertBytes: (bytes) => {
		const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

		if (bytes == 0) {
			return `0 ${sizes[2]}`;
		}

		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

		if (i == 0) {
			return bytes + " " + sizes[i];
		}

		return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
	},
	getAPI: async (params, cb, cb2 = null) => {

		if (!params.url) {
			return;
		}
		let nav = navigator || null;
		if (nav && !nav.onLine) {
			toast.error('Please check your internet connection or try again later.');
			if (cb2) {
				cb2();
			}
			return;
		}

		var detail = {
			method: params.method,

			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				//   mode: "no-cors",
				//   'Access-Control-Allow-Origin':"*",
				// "Cache-Control": "no-cache",
			},
		};

		if (params.isFormData) {
			detail.headers = {};
			detail["body"] = params.data;
		} else if (
			params.method == "post" ||
			params.method == "POST" ||
			params.method == "patch" ||
			params.method == "PATCH" ||
			params.method == "put" ||
			params.method == "PUT"
		) {
			detail["headers"] = {
				"Content-Type": "application/json",
				"Accept": "application/json",
				// "Cache-Control": "no-cache",
			};
			detail["body"] = JSON.stringify(params.data);
		} else {
			if (Object.keys(params.data).length) {
				var str = [];
				for (var p in params.data) {
					if (params.data.hasOwnProperty(p)) {
						str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params.data[p]));
					}
				}
				if (params.url.indexOf("?") != -1) {
					params.url += "&" + str.join("&");
				} else {
					params.url += "?" + str.join("&");
				}
			}
		}



		let urlRegex = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)?/;
		params.url = config.REACT_APP_BASE_URL + params.url;
		// console.log(params.url,111);
		// return;


		let cname = window.location.href.split('/')[4];
		common.manageLoader(params, true); // apply loader show
		try {
			await fetch(params.url, detail).then(async (resp) => {
				
				if (resp.status == 401) {
					
					let login = window.location.href.includes("login");
					const lastPart = params.url.substring(params.url.lastIndexOf("/") + 1);
					
					if (login) {
						resp = await resp.json()
						cb(resp);
						return
					} else { 
						let contentType = resp.headers.get('Content-Type');
						if(contentType.includes("text/html")){
							appStore.getState().logout();
							window.location.replace(`/membership/${cname}/login`);
							document.cookie = `access_token_${cname}= "";`;
							
						}else{
							let s1= await resp.json()
							// console.log("s1",s1)
						if(s1.message=="Unauthorized"){
							if(login){
								cb(s1);
								return
							}else{
								appStore.getState().logout()
								window.location.replace(`/membership/${cname}/login`);
							}
							// console.log("inside messsageeeeee")
						}else{
							appStore.getState().logout()
							document.cookie = `access_token_${cname}= "";`;
							// console.log("inside recdirect")
							window.location.replace(`/membership/${cname}/login`);
						}
						}
						return
					}

				} else {
					resp = await resp.json()
					if (resp.message == 'Successfully logged out') {
						appStore.getState().logout()
						document.cookie = `access_token_${cname}= "";`;
						window.location.replace(`/membership/${cname}/login`);
					}
					common.manageLoader(params, false);
					// cb(resp);

					if (resp) {
						// if (resp.message && resp.message != "") {
						// 	toast.success(resp.message);
						// }


						cb(resp);
						// return resp;
					} else {
						if (resp.message && resp.message != "") {
							toast.error(resp.message);
						}
						if (cb2 != null) {
							cb2(resp);
						}
					}
				}
			}).catch((e) => {

				// console.log("error", e)
			})
		} catch (error) {
			common.manageLoader(params, false);

			if (cb2 != null) {
				cb2(error);
			}
		}
	},

	manageLoader: (params, status) => {
		if (typeof params == "object" && "isLoader" in params && params.isLoader == false) {
			return;
		}

		if (!["GET", "get"].includes(params.method) || (params.hasOwnProperty("isLoader") && params.isLoader == true)) {
			let t = document.querySelector("#siteLoader");
			if (t) {
				status ? t.classList.add("overlayLoader") : t.classList.remove("overlayLoader");
			}
		}
	},
	loadTableData: (params, cb) => {
		common.getAPI(
			{
				method: "GET",
				url: params.url,
				data: params.data,
			},
			(resp) => {
				cb({
					data: resp.data,
					fetchCount: resp.data.length,
					totalRecord: resp.totalRecord,
				});
			}
		);
	},
	tableNumCnt: (currentPage, dataPerPage) => {
		// dataPerPage = dataPerPage == 'all'?
		return currentPage == 1
			? 1
			: currentPage * dataPerPage - dataPerPage + 1;
	},
	dateFormatter: (date, formate = "D MMM YYYY h:mm A") => {
		return moment(date).format(formate);
	},
	copyData: (data) => {
		copy(data.target);
		toast.success(data.type + " copied successfully.");
	},
};

export let NoDataWrapper = (props) => {
	// console.log(props, 'PropesDataLoader');
	let myLoader = {
		cateListInAdmin: <BulletList />,
		// postGrid : (
		// 	<ContentLoader
		// 	  width={800}
		// 	  height={575}
		// 	  viewBox="0 0 800 575"
		// 	  backgroundColor="#f3f3f3"
		// 	  foregroundColor="#ecebeb"
		// 	  {...props}
		// 	>
		// 	  <rect x="537" y="9" rx="2" ry="2" width="140" height="10" />
		// 	  <rect x="14" y="30" rx="2" ry="2" width="667" height="11" />
		// 	  <rect x="12" y="58" rx="2" ry="2" width="211" height="211" />
		// 	  <rect x="240" y="57" rx="2" ry="2" width="211" height="211" />
		// 	  <rect x="467" y="56" rx="2" ry="2" width="211" height="211" />
		// 	  <rect x="12" y="283" rx="2" ry="2" width="211" height="211" />
		// 	  <rect x="240" y="281" rx="2" ry="2" width="211" height="211" />
		// 	  <rect x="468" y="279" rx="2" ry="2" width="211" height="211" />
		// 	  <circle cx="286" cy="536" r="12" />
		// 	  <circle cx="319" cy="535" r="12" />
		// 	  <circle cx="353" cy="535" r="12" />
		// 	  <rect x="378" y="524" rx="0" ry="0" width="52" height="24" />
		// 	  <rect x="210" y="523" rx="0" ry="0" width="52" height="24" />
		// 	  <circle cx="210" cy="535" r="12" />
		// 	  <circle cx="428" cy="536" r="12" />
		// 	</ContentLoader>
		// ),

		blocks: (
			< >
				{props.blockCount ? Array.from(Array(parseInt(props.blockCount)).keys()).map((i) => {
					let manageSml = 65,
						height = props.height ? props.height - manageSml : 360,
						width = props.width ? props.width : 360;
					return (
						<ContentLoader
							viewBox={`0 0 ${width} ${parseInt(height) + parseInt(manageSml)}`}
							speed="2"
							backgroundColor={"#dedede"}
							className={props.className}
							key={i}
						>
							<rect x="0" y="0" rx="5" ry="5" width={width} height={height} className="bg-light" />
							<rect x="0" y={parseInt(height) + 5} rx="4" ry="4" width={width} height="60" />
						</ContentLoader>
						// <ContentLoader
						// 	width={450}
						// 	height={400}
						// 	viewBox="0 0 450 400"
						// 	backgroundColor="#f0f0f0"
						// 	foregroundColor="#dedede"
						// 	{...props}
						// >
						// 	<rect x="43" y="304" rx="4" ry="4" width="271" height="9" />
						// 	<rect x="44" y="323" rx="3" ry="3" width="119" height="6" />
						// 	<rect x="42" y="77" rx="10" ry="10" width="388" height="217" />
						// </ContentLoader>
					);
				})
					:
					""
				}
			</>
		),
		media: (
			< >
				{props.blockCount
					? Array.from(Array(parseInt(props.blockCount)).keys()).map(
						(i) => {
							let manageSml = 65,
								height = props.height ? props.height - manageSml : 360,
								width = props.width ? props.width : 360;
							return (
								<ContentLoader
									viewBox={`0 0 ${width} ${parseInt(height) + parseInt(manageSml)}`}
									speed="2"
									backgroundColor={"#dedede"}
									className={props.className}
									key={i}
								>
									<rect x="0" y="0" rx="5" ry="5" width={props.width} height={props.height} className=" bg-light" />
								</ContentLoader>
							);
						}
					)
					: ""}
			</>
		),
		editor: (
			< >
				<ContentLoader
					viewBox={`0 0 ${props.width} ${parseInt(props.height)}`}
					speed="2"
					backgroundColor={"#dedede"}
					className={props.className}
				>
					<rect x="0" y="0" rx="5" ry="5" width={'1200px'} height={'70px'} className=" bg-light" />
					<rect x="0" y="95" rx="150" ry="0" width={'150px'} height={'650px'} className=" bg-light" />
					<rect x="170" y="95" rx="750" ry="0" width={'650px'} height={'650px'} className=" bg-light" />
					<rect x="850" y="95" rx="350" ry="0" width={'350px'} height={'650px'} className=" bg-light" />
				</ContentLoader>
			</>
		),
		projectLoader: (
			< >
				<ContentLoader
					viewBox={`0 0 ${props.width} ${parseInt(props.height)}`}
					speed="2"
					backgroundColor={"#dedede"}
					className={props.className}
				>
					<rect x="0" y="0" rx="5" ry="5" width={'1200px'} height={'70px'} className=" bg-light" />
					<rect x="0" y="95" rx="150" ry="0" width={'150px'} height={'650px'} className=" bg-light" />
					<rect x="170" y="95" rx="750" ry="0" width={'650px'} height={'650px'} className=" bg-light" />
					<rect x="850" y="95" rx="350" ry="0" width={'350px'} height={'650px'} className=" bg-light" />
				</ContentLoader>
			</>
		),
		table: (
			<tr>
				<td colSpan={props.colspan}>
					<ContentLoader
						viewBox="0 0 1100 70"
						backgroundColor={"#dedede"}
					>
						{/* Only SVG shapes */}
						<rect
							x="0"
							y="15"
							rx="5"
							ry="5"
							width="100%"
							height="25px"
						/>
						<rect
							x="0"
							y="45"
							rx="5"
							ry="5"
							width="100%"
							height="25px"
						/>
						<rect
							x="0"
							y="95"
							rx="5"
							ry="5"
							width="100%"
							height="25px"
						/>
					</ContentLoader>
				</td>
			</tr>
		),

	};

	return (
		<>
			{props.isLoading ? (
				myLoader[props.section]
			) : props.dataCount ? <></>

				: props.section == "table" ? (
					<tr>
						<td colSpan={props.colspan}> Data not found.</td>
					</tr>
				) : props.section == "cateListInAdmin" ? (
					<p className="pxa_dataNotFound_dv"> Data not found.</p>
				) : (
					<div className="pxa_no-ticket-wrapper pt-5 pb-5 ">
						<div className="pxa_blnk-ticket-info text-center">
							{/* <img src="/assets/images/Noresults.png" alt="" /> */}
							{svg.app.nodataFound}
							<h4>{props.title ? props.title : "Data not found."}</h4>
							<p>{props.subTitle ? props.subTitle : ""}</p>
						</div>
					</div>
				)}
		</>
	);
};


export let dataLoader = {
	commonLoader: (
		<div id="mainPageLoader" className={'mainPageLoader isopen'}>
			<div className='mc_isLoaderDesign'></div>
		</div>
	),
	myLearningVideo: (
		<ContentLoader
			speed={2}
			width={890}
			height={500}
			viewBox="0 0 890 500"
			backgroundColor={"#dedede"}
			foregroundColor="#ecebeb"
		>
			<rect x="0" y="0" rx="0" ry="0" width="890" height="500" className="bg-light" />
		</ContentLoader>
	),

	videoModule: (<ContentLoader
		speed={2}
		width={288}
		height={480}
		viewBox="0 0 288 480"
		backgroundColor={"#dedede"}
		foregroundColor="#ecebeb"
	>
		<rect x="0" y="0" rx="0" ry="0" width="288" height="480" className="bg-light" />
	</ContentLoader>),

	textLoader: (<ContentLoader
		speed={2}
		width={400}
		height={120}
		viewBox="0 0 400 120"
		backgroundColor={"#dedede"}
		foregroundColor="#ecebeb"
	>
		<rect x="0" y="10" rx="5" ry="5" width="150" height="20" />
		<rect x="0" y="60" rx="5" ry="5" width="400" height="20" />
	</ContentLoader>),
	tableLoader: (
		<ContentLoader
			speed={2}
			width={1160}
			height={50}
			viewBox="0 0 1160 50"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
		>
			<rect x="0" y="0" width="1160" height="50" />
			<rect x="0" y="53" width="1160" height="50" />
		</ContentLoader>
	),
	eventLoader:(

		<ContentLoader
		speed={2}
		width="100%"
		height={85}
		viewBox="0 0 100% 80"
		backgroundColor={'#eeeeee'}
			foregroundColor="#999"
		style={{ marginBottom: '5px' }}
		className="mc_eventScaleLoader_"
	  >
		<rect x="0" y="0" rx="5" ry="5" width="100%" height="80" />
	  </ContentLoader>
		
	)
}

export let LoadMoreBtn = (props) => {
	return (
		<>
			{props.status || props.isLoading ? (
				<div className="pxa_load-more-tickets text-center">
					<a href={void (0)} className="pxa_btn pxa_load-btn" onClick={props.onClick}>
						{props.isLoading ? "Loading..." : "Load More"}
					</a>
				</div>
			) : (
				""
			)}
		</>
	);
};

export let getRandomChar = () => {
	return Math.random().toString(36).slice(-8);
};

export let getStrongPassword = (minLength = 5) => {
	let passList = [
		"!@#$%^&*()_+{}:\"<>?|[];',./`~", //special char
		"abcdefghijklmnopqrstuvwxyz", //lower case
		"ABCDEFGHIJKLMNOPQRSTUVWXYZ", //upper case
		"0123456789", //numbers
	];
	var password = "";
	let j = 0;
	for (let i = 0; i <= minLength; i++) {
		let t = passList[j];
		password += t[Math.floor(Math.random() * t.length)];
		j = j >= passList.length - 1 ? 0 : j + 1;
	}

	return password;
};

export const convertToCSV = (objArray) => {
	var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
	var str = "";
	for (var i = 0; i < array.length; i++) {
		var line = "";
		for (var index in array[i]) {
			if (line != "") line += ",";
			line += '"' + array[i][index] + '"';
		}
		str += line + "\r\n";
	}
	return str;
};

export const exportCSVFile = (headers, items, fileTitle) => {
	// if (headers) {
	//     items.unshift(headers);
	// }

	// Convert Object to JSON
	var jsonObject = JSON.stringify(items);
	var csv = convertToCSV(jsonObject);

	var exportedFilenmae = fileTitle + ".csv" || "export.csv";
	var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, exportedFilenmae);
	} else {
		var link = document.createElement("a");
		if (link.download != undefined) {
			// feature detection
			// Browsers that support HTML5 download attribute
			var url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", exportedFilenmae);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
};

export const convertQueryStringToObj = (path) => {
	let querySt = {};
	let qString = path.split("?") || [];
	if (qString.length > 1) {
		let p = qString[1].split("&");
		p.map((d, i) => {
			let t = d.split("=");
			querySt[t[0]] = t[1];
		});
	}

	return querySt;
};

export const loadFont = (fontAry = []) => {
	if (fontAry.length) {
		let fontList = "";
		fontAry.map((f) => {
			fontList += `${encodeURIComponent(
				fontList != "" ? "|" : ""
			)}${f.replace(" ", "+")}`;
		});
		var stylesheet = document.createElement("link");
		stylesheet.rel = "stylesheet";
		stylesheet.class = "myFonts";
		stylesheet.href = "https://fonts.googleapis.com/css?family=" + fontList;
		stylesheet.crossOrigin = "anonymous";
		document.head.appendChild(stylesheet);
	}
};

export let manageScroll = (e, cb) => {
	let targetScrollHeight = e.target.scrollHeight - e.target.clientHeight;
	if (e.target.scrollTop >= targetScrollHeight) {
		cb();
	}
};

export function Pagination(props) {
	let prePostCnt = 2;
	let { totalRecords, perPage, isLoading, currentPage, type, dataRange, onClick } = props;
	let paginationButton = []
	let pageCnt = 1;
	for (let i = 1; i <= parseInt(totalRecords); i = i + parseInt(perPage)) {
		paginationButton.push(pageCnt++);
	}

	return (
		<>
			{
				!isLoading ?
					<div className="pxa_pagination">
						<div className="pxa_headPagination">
							<p>{'Showing ' + dataRange.start + ' To ' + dataRange.end + ' Of ' + totalRecords}</p>
						</div>
						<div className="pxa_pageBox">
							{
								paginationButton.length ?
									<a href={void (0)} key="0" onClick={(e) => {
										onClick(1);
									}} className={'pxa_next ' + (currentPage == 1 ? 'disabled' : '')} disabled>First</a> : ''

							}


							{
								paginationButton.map((pageNum) => {

									if (pageNum > parseInt(currentPage) + prePostCnt || parseInt(pageNum) + prePostCnt < parseInt(currentPage)) {
										if (pageNum == 1 || pageNum == paginationButton.length) {
											return <span key={pageNum} className={''}>...</span>;
										} else {
											return;
										}
									}
									return (
										<a href={void (0)} key={pageNum} className={currentPage == pageNum ? 'active' : ''} onClick={(e) => {
											onClick(pageNum);
										}}>{pageNum}</a>
									)
								})
							}

							{
								paginationButton.length ?
									<a href={void (0)} key={paginationButton.length + 1} onClick={(e) => {
										onClick(paginationButton.length);
									}} className={'pxa_next ' + (currentPage == paginationButton.length ? 'disabled' : '')}>Last</a>
								: <></>
							}
						</div>
					</div>
					: <></>
			}
		</>
	)
}

export function setMyState(setQuery, params) {
	for (const key in params) {
		setQuery((prevState) => ({
			...prevState,
			[key]: params[key],
		}));
	}
}

export async function authAction(type, data, cb) {

	//  console.log(data, 'AuthData');
	if (type == 'login') {
		let targetUrl = {
			login: 'login',
		}
		let cname = window.location.href.split('/')[4];
		await common.getAPI({
			isLoader: true,
			url: targetUrl[type],
			method: 'POST',
			data: data
		}, (resp) => {
			if (resp) {
				if (resp?.device_exist != true) {
					if (!resp?.access_token) {
						toast.error(resp.message);
					} else {
						Cookies.set(`access_token_${cname}`, resp?.access_token, { expires: resp.expires_in, path: '/membership/' + cname })
					}
				}
			} else {
				if (resp.message && resp.message != '') {
					toast.error(resp.message);
				}
			}
			cb(resp);
		}, () => {
			cb();
		});

	} else if (type == 'forgot_password') {

		await common.getAPI({
			isLoader: true,
			method: 'POST',
			url: 'forgot_password',
			data: {
				email: data.email,
				funnel_id: data.funnel_id,
				base_url: data.base_url,
			}
		}, (resp) => {
			if (resp.message && resp.message != '') {
				toast.success(resp.message);
			}
			cb();

		}, () => {
			cb();
		});
	} else if (type == 'reset_password') {
		await common.getAPI({
			isLoader: true,
			url: 'reset_password',
			method: 'POST',
			data: {
				email: data.email,
				confirmPassword: data.confirmPassword,
				password: data.password,
				funnel_id: data.funnel_id,
				token: data.token,
			}
		}, (resp) => {
			cb();
			// Router.push("/login");
		}, () => {
			cb();
		});
	} else if(type =="relogin"){
		let cname = window.location.href.split('/')[4];
		await common.getAPI({
			isLoader: true,
			url: "autologin",
			method: 'POST',
			data: {code : data}
		}, (resp) => {
			// console.log(resp, 'device_name');
			if (resp) {
				if (resp?.device_exist != true) {
					if (!resp?.access_token) {
						toast.error("Invalid email or password.");
					} else {
						Cookies.set(`access_token_${cname}`, resp?.access_token, { expires: resp.expires_in, path: '/membership/' + cname })
					}
				}


			} else {
				if (resp.message && resp.message != '') {
					toast.error(resp.message);
				}
			}
			cb(resp);
		}, () => {
			cb();
		});
	}
}

// export function resetLoginDataRedux(respData){
//    Cookies.set('access_token', respData.access_token , { expires: 1 });
// 	//	myStore.updateStoreData('userData' , {});
// }

export function logout(cb) {
	let cname = window.location.href.split('/')[4];
	Cookies.remove(`access_token_${cname}`)
	window.location.replace("/")
	//   Router.push("/");
	cb();
}


const passwordRegex = /^(?=.*[a-z])(?=.*[0-9\W]).{8,}$/;
export function validatePassword(password) {
	return passwordRegex.test(password);
}

